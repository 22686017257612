const strings = {
  article: 'Artikel',
  news: 'Berita',
  partner: 'Mitra',

  //table partner
  add_partner: 'Tambah Mitra',
  list_health_facilities: 'Daftar Fasilitas Kesehatan',
  number: 'No. ',
  name: 'Nama',
  type_place: 'Jenis',
  telephone: 'Telepon',
  status: 'Status',
  action: 'Aksi',
  choose_action: 'Pilih Aksi',
  list_action: 'Daftar Aksi',
  polyclinic: 'Poliklinik',
  edit_partner: 'Ubah Mitra',
  edit_polyclinic: 'Ubah Poliklinik',
  drugs: 'Obat',
  edit_drugs: 'Ubah Obat',
  edit_cs: 'Ubah CS',
  address: 'Alamat',
  latitude: 'Latitude',
  longitude: 'Longitude',
  clinic: 'Klinik',
  hospital: 'Rumah Sakit',
  drug_store: 'Apotik',
  laboratory: 'Labaoratorium',
  insurance: 'Asuransi',
  pick_one: 'Pilih Salah Satu',
  active: 'Aktif',
  not_active: 'Tidak Aktif',
  is_admin_payment: 'Ada Biaya Admin',
  there_is: 'Ada',
  there_is_not_any: 'Tidak ada',
  is_doctor_payment: 'Ada Biaya Dokter',
  type_queue: 'Jenis Antrian',
  odd: 'Ganjil',
  even: 'Genap',
  barcode_medical_facility: 'Barcode Faskes',
  admin_payment: 'Biaya Admin',
  drugs_delivery_payment: 'Biaya Antar Obat',
  online_queue_payment: 'Biaya Jadwal Online',
  doctor_online_payment: 'Biaya Dokter Online',
  doctor_online_duration: 'Durasi Dokter Online',
  open_time: 'Waktu Buka',
  close_time: 'Waktu Tutup',

  //table article
  add_article: 'Tambah Artikel',
  list_article: 'Daftar Artikel',
  edit_article: 'Ubah Artikel',
  delete_article: 'Hapus Artikel',
  type: 'Tipe',
  title: 'Judul',

  //table role
  add_role: 'Tambah Role',
  list_role: 'Daftar Role',
  edit_role: 'Ubah Role',
  edit_menu: 'Ubah Menu',
  role: 'Role',

  //polyclinic
  add_poly: 'Tambah Poli',
  list_poly: 'Daftar Poli',
  list_doctor: 'Daftar Dokter',
  add_doctor: 'Tambah Dokter',
  add_doctor_existing: 'Tambah Dokter Existing',
  edit_doctor: 'Ubah Dokter',
  edit_poly: 'Ubah Poli',

  ind_lang: 'Indonesia',
  eng_lang: 'Inggris',

  //user
  add_user: 'Tambah User',
  list_user: 'Daftar User',
  email: 'Email',
  medical_facility: 'Faskes',
  edit_user: 'Ubah User',

  //menu
  add_menu: 'Tambah Menu',
  list_menu: 'Daftar Menu',
  
  //send_drugs
  queue_list: 'Daftar Antrian',
  waiting_list: 'Menunggu Pembayaran',
  cancel: 'Dibatalkan',
  new: 'Baru',
  formulated: 'Memesan Obat',
  sending: 'Dikirim',
  accepted: 'Diterima',
  no_order: 'No. Order',
  driver_name: 'Nama Driver',
  driver_plat: 'Plat Kendaraan',
  phone: 'No. Telp',
  drugs_created: 'Waktu Pemesanan',
  drugs_sended: 'Waktu Pengiriman',
  drugs_accepted: 'Waktu Penerimaan',

  //online schedule
  queue_online_schedule: 'Daftar Jadwal Online',

  //doctor online
  queue_online_doctor: 'Daftar Dokter Online',
  detail: 'Detail',

  //payment
  transaction_id: 'Id Transaksi',

  //list admin
  list_admin: 'Daftar Admin',
  add_admin: 'Tambah Admin',
  edit_admin: 'Edit Admin',

  //list icds
  list_icds: 'Daftar Diagnosa',
  add_icds: 'Tambah Diagnosa',
  edit_icds: 'Edit Diagnosa',
  description: 'Deskripsi',

  //medicine
  list_medicine: 'Daftar Obat',
  add_medicine: 'Tambah Obat',
  edit_medicine: 'Edit Obat',
  code: 'Kode',
  price: 'Harga',
  stock: 'Stok',
  medicine_type: 'Satuan',

  //family
  check_family: 'Lihat Keluarga',
  list_family: 'Daftar Keluarga',
  add_family: 'Tambah Anggota Keluarga',
  check_address: 'Lihat Alamat',
  address_list: 'List Alamat',
  edit_address: 'Edit Alamat',

  //tnc
  list_tnc: 'Daftar Terms and Conditions',
  edit_tnc: 'Edit TNC',
  add_tnc: 'Tambah Terms and Conditions',
  tnc_contents: 'Isi TNC',
  tnc_created: 'TNC Dibuat',
  tnc_ended: 'TNC Selesai',
  
  //payment
  list_payment: 'Daftar History Pembayaran',

  //homecare
  list_homecare: 'Daftar Home Care',
  services_ordered: 'Layanan yang dipesan',

  //doctor online
  list_doctor_medicine: 'Daftar Obat Dokter Online',
  list_doctor_diagnose: 'Daftar Diagnosa Dokter Online',
  list_doctor_chat: 'Daftar Chat Dokter Online',
  doctor_name: 'Nama Dokter',
  medicine_name: 'Nama Obat',
  how_to_consume: 'Cara Mengkonsumsi',
  qty: 'Jumlah',
  diagnose_name: 'Diagnosa',
  see_chat: 'Lihat Chat',
  patient: 'Pasien',
  last_chat: 'Chat Terakhir',
  date: "Tanggal",

  first_name: 'Nama Awal',
  last_name: 'Nama Akhir',

  city: 'Kota',
  country: 'Negara',
  postal_code: 'Kode Pos',
  logo: 'Logo',
  header_image: 'Foto Header',
  
  data_not_found: 'Data tidak ditemukan',

  //form doctor
  front_degree: 'Gelar Depan',
  back_degree: 'Gelar Belakang',
  graduate: 'Lulusan',
  experience: 'Pengalaman',
  sip: 'SIP',
  no_ktp: 'No. KTP',
  appointment_fee: 'Harga Appointment',
  online_fee: 'Harga Online',
  chat_limit: 'Limit Chat',
  gender: 'Jenis Kelamin',
  password: 'Kata Sandi',
  birthday: 'Tanggal Lahir',

  //diagnose:
  section: 'Section',
  chapter: 'Chapter',
  label: 'Label',

  version: 'Version',
  additional_settings: 'Pengaturan Tambahan',
  no_additional_settings: 'Tidak ada pengaturan tambahan',

  choose_doctor: 'Pilih Dokter',
  change_schedule: 'Ubah Jadwal',
  schedule: 'Jadwal',
  day: 'Hari',
  start: 'Mulai',
  end: 'Selesai',
  edit_schedule: 'Ubah Jadwal',
  see_schedule: 'Lihat Jadwal',
  schedule_doctor: 'Jadwal Dokter ',
  add_schedule: 'Tambah Jadwal',
  edit_stock: 'Edit Stok',
  edit_price: 'Edit Harga',
  content:  'Content',
  id_transaction: 'ID Transaksi',
  information_patient: 'Informasi Pasien',
  information_doctor: 'Informasi Dokter',
  detail_diagnose: 'Detail Diagnosa',
  detail_services: 'Detail Servis Homecare',
  detail_medicine: 'Detail Obat',
  medicine_price: 'Harga(Qty)',
  how_to_consume: 'Cara Penggunaan',
  code_transaction:  'Kode Transaksi',

  history_user: 'History Dokter - Pasien',
  history_diagnose: 'History Dokter - Penyakit',
  history_medicine:'History Dokter - Obat',
  homecare_services: 'Layanan Homecare',
  add_services: 'Tambah Layanan',
  edit_services: 'Ubah Layanan',
  services_name: 'Nama Layanan',
  services_desc: 'Deskripsi Layanan',
  services_price: 'Harga Layanan',

  doctor: 'Dokter',
  nurse: 'Perawat',
  choose_doctor_nurse: 'Pilih Status Dokter',
  status_doctor:  'Status Dokter',
  delete_schedule: 'Hapus Jadwal',
  are_you_sure: 'Apakah anda yakin ingin menghapus item ini?',
  yes: 'Ya',
  no: 'Tidak',
  search: 'Cari',
  total_diagnose: 'Jumlah Penyakit',
  total_medicine:'Jumlah Obat',
  see: 'Lihat',
  please_select_month_and_year: 'Silahkan untuk memilih bulan dan tahun terlebih dahulu',
  image: 'Foto',
  edit: 'Ubah',
  delete: 'Hapus',
  price_delivery: 'Harga Ongkir',
  detail_medicine: 'Detail Obat',
  update_status: 'Ubah Status',
  
  list_banner: 'Daftar Banner',
  add_banner: 'Tambah Banner',
  total_price_medicine: 'Total Harga Obat',
  total_price_need_to_play: 'Total yang harus dibayar',
  driver_plat: 'Nomor Plat Driver',
  driver_phone: 'Nomor Telepon Driver',
  driver_name: 'Nama Driver',
  airway_bill: 'Kode Tracking',

  send_drugs: 'Kirim Obat',
  complaint: 'Keluhan',

  please_select_your_poly: 'Silahkan untuk memilih poly terlebih dahulu',
  height_and_weight: 'Tinggi (cm) dan Berat (kg)',
  allergy: 'Alergi' ,
  block_user: 'Blokir',
  unblock_user: 'Buka Blokir',

  // code: 'Kode',
  list_code: 'Daftar Kode Wellings',
  add_code: 'Tambah Kode Wellings',
  edit_code: 'Ubah Kode Wellings',
  code_list_location_do: 'Kode List Location DO',
  store: 'Apotek',
  email_cc:'Email CC',
  
  are_you_sure_blockir: 'Apakah anda yakin ingin memblokir user ini?',
  are_you_sure_unblockir: 'Anda akan men-unblockir user ini, apakah anda yakin?',
  report_wellings: 'Report Wellings',
  start_date: 'Tanggal Awal',
  end_date: 'Tanggal Akhir',
  see_report: 'Lihat Laporan',
  total_consult: 'Total Konsultasi',
  list_diagnose: "Daftar Diagnosa",
  detail_consult: 'Detail Konsultasi',
  note_doctor: 'Catatan Dokter',
  advice_doctor: 'Saran Dokter',
  rating: 'Kepuasan',
  medicine_list: 'Daftar Obat',

  log_delivery: 'Log Antar Obat',
  log_stock: 'Log Stok Obat',
  log_price: 'Log Harga Obat',
  log_payment: 'Log Pembayaran',
  old_price: 'Harga Lama',
  new_price: 'Harga Baru',
  old_stock: 'Stok lama',
  new_stock: 'Stok baru',
  // number_transaction: 'Nomor Transaksi'
  total_prescription: 'Total Konsultasi 90 Hari Terakhir',
  total_prescription_doctor: 'Total Konsultasi 30 Hari Terakhir Perdokter',
  total_medicine_location: 'Total Obat Yang Dipesan Perlokasi',
  top_ten_medicine: 'Top 10 Obat Yang Sering Dipesan',
  top_ten_diagnose: 'Top 10 Penyakit',

  patient_name: 'Nama Pasien',
  age: 'Umur',
  download: 'Unduh Data (Excel)',
  wellings_name: 'Nama Wellings',

  patient_name: 'Nama Pasien',
  nik: 'NIK',
  religion: 'Agama',
  profession: 'Pekerjaan',
  education: 'Pendidikan',
  marital_status: 'Status Perkawinan',

  update_rekam_medis_approval: 'Update Rekam Medis Approval',
  //refund

  bentuk_sediaan: 'Bentuk Sediaan',
  logo_obat: 'Logo Obat',
}
export default strings
